body {
  background-color: #FFF;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  pointer-events: none;
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
}

a {
  text-decoration: none;
  color: #000;
}

.App-body-logo {
  min-width: 300px;
  width: 350px;
  opacity: 60%;
}

@media (max-width: 800px) {
   .App-body-logo { 
      display: none;
   }
}

.App-header-right {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
}

.App-header-right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-header-left {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
}

.App-body {
  background-color: #FFF;
  font-family: 'Baloo 2';
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
  font-weight: 800;
  color: #1d1f22;
}

.App-body-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 34px;
  font-weight: 500;
  align-items: center;
  margin-left: 40px;
  color: #fff;
}

.App-body-text-container {
  width: 700px;
  display: flex;
  align-items: start;
  flex-direction: column;
}

.App-body-splash-background {
  background-color: #1d1f22;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-body-splash {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: calc(100vw - 60px);
  max-width: 1500px;
}

.App-body-eta-background {
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-height: 350px;
}

.App-body-eta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: 'Baloo 2';
  font-size: 28px;
  font-weight: 600;
  color: #343434;
}

.App-body-eta-description {
  max-width: 600px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 500;
}

.App-body-eta-title {
  font-size: 28px;
  font-weight: 800;
}

.calendar-icon {
  height: 60px;
  align-self: center;
  fill: #11d566;
  color: #8affa6;
  margin-bottom: 12px;
}

@media (max-width:1200px) {
  .App-body-text {
    display: none;
  }
}

.App-link {
  color: #61dafb;
}

.App-footer-background {
  width: calc(100vw);
  font-family: 'Baloo 2';
  font-size: 13.5px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-footer {
  max-width: 1500px;
  flex-grow: 1;
}

.App-footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  margin-left: 18px;
  color: rgb(117, 117, 117);
  flex-grow: 1;
}

.App-footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
}

.footer-right {
  flex-grow: 1;
  display: flex;
}


.text-1 {
  animation: text1;
}

.text-2 {
  animation: text2;
}

.text-3 {
  animation: text3;
}

.text-1,
.text-2,
.text-3 {
  color: #fff;
  font-weight: 800;
  font-size: 60px;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 23s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.text-1::after,
.text-2::after,
.text-3::after {
  content: "|";
  position: absolute;
  right:-3.5px;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
}

@keyframes text1 {

  0%,
  33.3%,
  66.6%,
  100% {
    width: 0;
  }

  8%,
  25.3% {
    width: 12.4em;
  }
}

@keyframes text2 {

  0%,
  33.3%,
  66.6%,
  100% {
    width: 0;
  }

  41.3%,
  58.6% {
    width: 9.35em;
  }
}

@keyframes text3 {

  0%,
  33.3%,
  66.6%,
  100% {
    width: 0;
  }

  74.6%,
  92% {
    width: 8.98em;
  }
}

@keyframes caret {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}