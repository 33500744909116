
.Dashboard-container {
  display: flex;
  flex-direction: column;
}

.Dashboard-body-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: #fff;
}

.Dashboard-body {
  background-color: #202124;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-left: 32px;
  flex-grow: 1;
  width: 900px;
}

h2 {
  font-family: 'Assistant';
  font-size: 38px;
  font-weight: 600;
}

h4{
  font-family: 'Assistant';
  font-size: 20px;
  font-weight: 300;
}

.Dashboard-body-main {
  max-width: 1200px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}