
.Chat-row {
    display: flex;
    max-height: 32px;
    flex-grow: 1;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 1px;
    margin: 0;
}
.Chat-row:hover {
    background-color: #1d1f22;
    border-radius: 4px;
}

.Chat-body {
    font-family: 'Baloo 2';
    font-weight: 300;
    color: #fff;
    align-items: flex-start;
    flex-flow: column-wrap;
    font-size: 18px;
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden; /* Hide scrollbars */
}

.Chat-box {
    margin-top: 15px;
    background-color: #343538;
    width: 100%;
    color: #fff;
    padding: 10px;
    border-radius: 6px;
    border: 0px solid;
    resize: none;
}

.Chat-box:focus {
    outline: none !important;
    -webkit-box-shadow:inset 0px 0px 0px 3px #8affa6;
    -moz-box-shadow:inset 0px 0px 0px 3px #8affa6;
    box-shadow:inset 0px 0px 0px 3px #8affa6;
    background-color: #1d1f22;
}