.Login-modal-background {
  background-color: #fff;
}

.Login-body {
  font-family: 'Baloo 2';
  font-weight: 200;
  background-color: #313336;
  color: #fff;
}

.Login-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-family: 'Baloo 2';
  margin-bottom: 0;
  padding-bottom: 0;
}

.Login-header-text {
  font-weight: 600;
  font-size:25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-border {
  border: 0px !important;
  -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.Login-form-label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.Login-form-input {
  border-radius: 3px;
  background-color: #1d1f22;
  color: #fff;
  border: 0px;
  padding: 8px;
  width: 100%;
  font-size: 16px;
}

.Login-form-input:focus {
    border: 0;
    outline: none !important;
    -webkit-box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
    -moz-box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
    box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
}

.Login-error-text {
  color: #FF0000;
  font-weight:600;
  margin-top: 12px;
  background-color: #FFEDED;
  padding: 12px;
  border-radius: 3px;
}

.Login-form-button {
  font-weight: 600;
  color: #1d1f22;
  padding: 8px;
  border-radius: 5px;
  margin-left:12px;
  margin-right:12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 40px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Login-form-loading {
  font-size: 25px;
  height: 40px;
  padding: 8px;
  border-radius: 5px;
  margin-left:12px;
  margin-right:12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Login-modal-body {
  padding-left: 24px;
  padding-right: 24px;
  line-height: 25px;
}

.Login-modal-footer {
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  background-color: #8affa6;
}

.Login-modal-footer:hover {
  background-color: #54ff7c;
}