.Header {
  display: flex;
  flex-direction: row;
  width: calc(100vw);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 18px;
  padding-bottom: 16px;
  background-color: #1d1f22;
}

.Header-right {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
}

.Header-right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header-left {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
}

.Header-logo {
  font-family: 'Baloo 2';
  color: #fff;
  font-weight: 600;
  font-size: 34px;
}

.Login-button {
  font-family: 'Baloo 2';
  background-color: #8affa6;
  color: #313336;
  margin-left: 8px;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.Login-button:hover {
  background-color: #54ff7c;
  cursor: pointer;
}

.Signup-button {
  font-family: 'Baloo 2';
  background-color: #313336;
  color: #fff;
  margin-left: 8px;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-size: 15px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.Signup-button:hover {
  cursor: pointer;
}