.Dashboard-top {
  display: flex;
  flex-direction: column;
  width: calc(100vw);
  padding: 14px;
  padding-bottom: 0px;
  padding-left: 22px;
  background-color: #1d1f22;
  border-bottom: 1px solid rgb(51, 51, 51);
}

.Dashboard-top-menu {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  color: #fff;
}

.Dashboard-top-menu-item {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Dashboard-top-menu-item:hover{
  cursor: pointer;
}

.Dashboard-top-menu-item-select {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
  border-bottom: 2px solid #8affa6;
  padding-bottom: 4px;
  color: #8affa6;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Dashboard-top-menu-item-select:hover {
  cursor: pointer;
}

.Dashboard-header {
  display: flex;
  flex-direction: row;
}

.Dashboard-header-right {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
}

.Dashboard-header-right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.Dashboard-header-left {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
}

.Dashboard-header-logo {
  font-family: 'Baloo 2';
  color: #fff;
  font-weight: 600;
  font-size: 25px;
}

.Dashboard-header-logo-slogan {
  font-family: 'Baloo 2';
  color: #8affa6;
  font-size: 16px;
  margin-left: 4px;
  margin-top: 7px;
}