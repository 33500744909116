.Email-Form-Input {
  font-family: 'Baloo 2';
  line-height: 32px;
  border-radius: 3px;
  background-color: #e7e8ec;
  color: #000;
  border: 0px;
  padding: 8px;
  width: 497px;
  font-size: 18px;
}

.Email-Form-Input:focus {
  border: 0;
  outline: none !important;
  -webkit-box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
  -moz-box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
  box-shadow:inset 0px 0px 0px 3px rgb(100, 100, 100);
}

.Email-Form-Signup-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Email-Form-Submit {
  line-height: 30px;
  font-family: 'Baloo 2';
  font-size: 16px;
  padding: 9.0px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
  border: 0px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  background-color: #11d566;
}